<template>
  <v-container class="py-12">
    <v-row v-if="properties.length > 0">
      <v-col
        v-for="(item, i) in properties"
        :key="i"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
      >
        <PropertyCard :property="item" @submit="submit(item.id)" />
      </v-col>
    </v-row>

    <div v-else class="text-center">
      <span> Nenhuma UCS disponível no momento </span>
    </div>
  </v-container>
</template>

<script>
import PropertyCard from "@/components/investment/PropertyCard";

export default {
  props: {
    properties: {
      type: Array,
      default: [],
    },
  },

  components: {
    PropertyCard,
  },

  methods: {
    submit(id) {
      this.$router.push({ path: "/investment/" + id });
    },
  },
};
</script>

<style></style>
