<template>
  <v-card
    class="rounded-lg"
    height="100%"
    :disabled="_disabled"
    outlined
    @click="submit()"
  >
    <v-sheet class="rounded-t-lg rounded-b-0" color="accent">
      <v-responsive :aspect-ratio="16 / 9">
        <v-img
          v-if="property.images.length"
          class="rounded-t-lg rounded-b-0"
          :src="_images[0]"
          :aspect-ratio="16 / 9"
        >
          <div
            v-if="property.sharesSold > 0"
            class="d-flex align-top justify-end fill-height pa-2"
          >
            <v-chip color="red" dark small label>
              {{ property.sharesSold }}% de cotas vendidas
            </v-chip>
          </div>
        </v-img>
      </v-responsive>
    </v-sheet>

    <section class="pa-4">
      <div class="d-flex flex-column" style="min-height: 150px">
        <div class="mb-2">
          <span class="primary--text text-h5 font-weight-bold">
            <span> R$ {{ currencyMask(property.minValue, 2, 2) }} </span>
          </span>
          <span class="text-body-2 subtext--text">
            <span> /Investimento mínimo </span>
          </span>
        </div>

        <div class="overtext--text text-h5 font-weight-bold mb-2">
          <span>
            {{ property.title }}
          </span>
        </div>

        <div class="text-body-1 subtext--text truncate">
          <span>
            {{ property.location }}
          </span>
        </div>
      </div>

      <v-divider class="my-3" />

      <div class="d-flex flex-wrap">
        {{ _disabled ? "Sucesso total nas vendas" : property.comment }}
      </div>
    </section>
  </v-card>
</template>

<script>
import { currencyMask } from "@/utils";

export default {
  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  computed: {
    _images() {
      return this.property.images.map((el) => this.$store.getters.apiURL + el);
    },

    _disabled() {
      return Number(this.property.sharesSold) === 100;
    },
  },

  methods: {
    submit() {
      this.$emit("submit", this.property);
    },

    currencyMask,
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  max-height: calc(1.2em * 3); /* Altura máxima equivalente a três linhas */
  line-height: 1.2em; /* Altura de uma linha */
  overflow: hidden; /* Oculta o texto excedente */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Número de linhas permitidas */
  -webkit-box-orient: vertical;
  word-wrap: break-word; /* Permite a quebra de palavras */
}
</style>
